import { render, staticRenderFns } from "./[studentId].vue?vue&type=template&id=79b35b02&scoped=true"
import script from "./[studentId].vue?vue&type=script&lang=js"
export * from "./[studentId].vue?vue&type=script&lang=js"
import style0 from "./[studentId].vue?vue&type=style&index=0&id=79b35b02&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "79b35b02",
  null
  
)

export default component.exports