<template>
  <b-container>
    <b-row>
      <b-col>
        <div class="pb-0">
          <b-col>
            <div class="d-flex align-items-center">
              <div
                class="z-icon-wrapper border cp"
                @click="$router.push(`/triedy/${$route.params.id}`)"
              >
                <svg-arrow-left-icon />
              </div>
              <div v-if="student" class="d-flex align-items-center">
                <!-- <svg-account-box-icon class="z-group-title-icon ml-2" /> -->
                <svg-graduation-cap-icon class="z-group-title-icon ml-2" />
                <h1 class="ml-2 mb-0">
                  {{ student.attributes.user.data.attributes.firstName }}
                </h1>
                <h1 class="ml-2 mb-0">
                  {{ student.attributes.user.data.attributes.lastName }}
                </h1>
              </div>
            </div>
          </b-col>
          <b-col>
            <div class="d-flex flex-md-row-reverse group">
              <p v-if="group">
                Trieda:
                <b
                  class="group-name fw-bold cp"
                  @click="
                    $router.replace({ path: `/triedy/${$route.params.id}` })
                  "
                >
                  {{ group.attributes.name }} {{ group.attributes.subject }}
                </b>
              </p>
            </div>
            <div class="d-flex flex-md-row-reverse">
              <b-button
                class="p-0"
                variant="outline"
                v-if="student"
                @click="$refs['remove-student-modal'].showModal(student)"
              >
                Vymazať z triedy
              </b-button>
            </div>
          </b-col>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-tabs>
          <b-col>
            <b-tab title="Úlohy" active>
              <GroupTable
                v-if="student"
                :tableItems="student.attributes.tasks"
                :tableFields="adminStudentTasksFields"
                @rowClicked="navigateToTask"
                class="--clickable-rows"
              />
            </b-tab>
            <b-tab title="Informácie">
              <b-row class="mt-3">
                <b-col class="cell-border mt-3 mr-3 pb-2">
                  <p class="mb-2">Meno</p>
                  <b v-if="student">
                    {{ student.attributes.user.data.attributes.firstName }}
                  </b>
                </b-col>
                <b-col class="cell-border mt-3 mr-3 pb-2">
                  <p class="mb-2">Priezvisko</p>
                  <b v-if="student">
                    {{ student.attributes.user.data.attributes.lastName }}
                  </b>
                </b-col>
                <b-col class="cell-border mt-3 mr-3 pb-2">
                  <p class="mb-2">E-mail</p>
                  <b v-if="student">
                    {{ student.attributes.user.data.attributes.email }}
                  </b>
                </b-col>
              </b-row>
              <b-row class="mt-3">
                <b-col class="cell-border mt-3 mr-3 pb-2">
                  <p class="mb-2">Počet vypracovaných zadaní</p>
                  <b
                    v-if="
                      student &&
                      student.attributes &&
                      student.attributes.tasks &&
                      student.attributes.tasks.length
                    "
                  >
                    {{ student.attributes.completedTaskCount }} /
                    {{ student.attributes.tasks.length }}
                  </b>
                </b-col>
                <b-col class="cell-border mt-3 mr-3 pb-2">
                  <p class="mb-2">Dátum registrácie</p>
                  <b v-if="student">
                    {{
                      formatDate(
                        student.attributes.user.data.attributes.createdAt
                      )
                    }}
                  </b>
                </b-col>
                <b-col> </b-col>
              </b-row>
            </b-tab>
          </b-col>
        </b-tabs>
      </b-col>
    </b-row>
    <RemoveModal
      ref="remove-student-modal"
      v-if="student && group"
      header-text="remove-profile-confirm"
      @remove="removeStudentFromGroup"
    >
      <p>
        {{ $t("remove-confirm") }}
        <b class="fw-bold">
          {{ student.attributes.user.data.attributes.firstName }}
          {{ student.attributes.user.data.attributes.lastName }}
        </b>
        {{ $t("from-group") }}
        <b class="fw-bold">
          {{ group.attributes.name }} {{ group.attributes.subject }}?
        </b>
      </p>
    </RemoveModal>
  </b-container>
</template>

<script>
import moment from "moment-timezone";
import axios from "/utils/axios";
import { get } from "lodash";
import * as Sentry from "@sentry/vue";

const api = {
  getGroup: (groupId) => {
    return axios.getAuth(`groups/${groupId}`, {
      params: {
        populate: [
          "tasks.course.thumbnail",
          "tasks.responses",
          "tasks.course.quiz.questions.answers",
        ],
      },
    });
  },
  getGroupMember: (memberId) => {
    return axios.getAuth(`group-members/${memberId}`, {
      params: {
        populate: ["user", "taskResponses.task"],
      },
    });
  },
  removeStudentFromGroup: (memberId) => {
    return axios.deleteAuth(`group-members/${memberId}`);
  },
};

export default {
  layout: "profile",

  components: {
    GroupTable: () => import("/components/Group/GroupTable.vue"),
    RemoveModal: () => import("/components/RemoveModal.vue"),
    "svg-arrow-left-icon": () => import("/assets/icons/arrowLeft.svg?inline"),
    "svg-graduation-cap-icon": () =>
      import("/assets/icons/graduationCap.svg?inline"),
    // 'svg-account-box-icon': () => import('/assets/icons/accountBox.svg?inline')
  },

  data() {
    return {
      adminStudentTasksFields: [
        { key: "courseImg", label: "Kurz" },
        { key: "course", label: "" },
        { key: "starts_at", label: "Termín zadania úlohy" },
        { key: "deadline", label: "Termín splnenia úlohy" },
        { key: "exams_count", label: "Počet bodov" },
      ],
      student: null,
      group: null,
    };
  },

  async created() {
    await this._loadUser();
  },

  methods: {
    formatDate(t) {
      return moment(t).utc().tz("Europe/Bratislava").format("DD.MM.YYYY");
    },
    async removeStudentFromGroup(student) {
      this.$toast.clear();
      try {
        await api.removeStudentFromGroup(student.id);

        this.$toast.success("Študent/ka bol/a úspešne odstránený/á");
        this.$nextTick(() => {
          this.$router.push(`/triedy/${this.$route.params.id}`);
        });
      } catch (error) {
        Sentry.captureException(error);
        this.$toast.error(error);
      }
    },
    async navigateToTask(item) {
      this.$router.push(`/triedy/${this.$route.params.id}/uloha/${item.id}`);
    },
    async _loadUser() {
      const groupMember = await api.getGroupMember(
        this.$route.params.studentId
      );

      await this._loadGroup();

      const tasks = get(this.group, "attributes.tasks.data") || [];
      const taskResponses =
        get(groupMember, "data.attributes.taskResponses.data") || [];

      const completedTaskCount = tasks.filter(({ id }) =>
        taskResponses.find(
          (response) => get(response, "attributes.task.data.id") === id
        )
      ).length;

      this.student = groupMember.data;

      this.student.attributes.tasks = tasks;
      this.student.attributes.tasks.forEach((task) => {
        // Keep only the response, which belongs to the current student
        const response = taskResponses.find(
          (response) => get(response, "attributes.task.data.id") === task.id
        );
        task.attributes.responses.data = [response];
      });
      this.student.attributes.completedTaskCount = completedTaskCount;
    },
    async _loadGroup() {
      const group = await api.getGroup(this.$route.params.id);
      this.group = group.data;
    },
  },
};
</script>

<style lang="scss" scoped>
.cp {
  cursor: pointer;
}

h1 {
  font-size: 20px;
  color: #2b2e4a;
  display: inline-block;
}

button {
  width: 192px;
  height: 39px;
  left: 1384px;
  top: 337px;
  border-radius: 100px;
  font-weight: 700;
  color: #86b2cd !important;
  border-color: #86b2cd !important;
  font-size: small;
  font-weight: bold;
  &:hover {
    background-color: #2b2e4a;
    color: #ffffff !important;
    border-color: #2b2e4a !important;
  }
}

p {
  color: #2b2e4a;
  font-weight: lighter;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
}

.group {
  p {
    color: #86b2cd;
  }
  b {
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 40px;
  }
}

.group-name {
  color: #333333;
}

.a-icon-wrapper {
  cursor: pointer;
}

.cell-border {
  border-bottom: 1px solid #cadeea;
}
</style>
